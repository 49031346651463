import React, { Fragment, ReactElement } from "react";
import Marquee from "react-fast-marquee";

import Box from "@/design-system/Box";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";
import { IconVarient } from "@/design-system/Icon/types";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

interface SliderTextProps {
  data: any[];
  pageTheme?: any;
  icon?: ReactElement;
  render?: any;
  gradientWidth?: number;
  parentContStyle?: any;
}

const MarqueComponent = ({ data, pageTheme, icon, render, gradientWidth }: SliderTextProps) => {
  return (
    <Box>
      <Marquee gradientWidth={gradientWidth && 100} gradient={true} speed={100} direction="left">
        <FlexContainer alignItems={"center"}>
          {[...data, ...data].map((md, key) => {
            return (
              <Fragment key={key}>
                <React.Fragment key={key}>
                  {icon ? (
                    icon
                  ) : (
                    <Box ml={"48px"} mr={"8px"}>
                      <Icon
                        type={"star-05"}
                        varient={IconVarient.solid}
                        size={40}
                        color={
                          pageTheme
                            ? theme.colors.blue[key % 2 == 0 ? 900 : 700]
                            : theme.colors.primary[key % 2 == 0 ? 800 : 700]
                        }
                        stokeColor={"none"}
                      />
                    </Box>
                  )}
                </React.Fragment>
                {render ? (
                  render(md)
                ) : (
                  <Typography
                    varient={"displays"}
                    color={
                      pageTheme
                        ? theme.colors.blue[key % 2 == 0 ? 900 : 700]
                        : theme.colors.primary[key % 2 == 0 ? 800 : 700]
                    }
                    weightVarient={"semibold"}
                    as={"h3"}
                  >
                    {md}
                  </Typography>
                )}
              </Fragment>
            );
          })}
        </FlexContainer>
      </Marquee>
    </Box>
  );
};
const SliderText = (props: SliderTextProps) => {
  return (
    <Box width={"100%"} overflow={"hidden"} style={props?.parentContStyle || {}}>
      <Box>
        <MarqueComponent {...props} />
      </Box>
    </Box>
  );
};

export default SliderText;
